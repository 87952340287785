<template>
  <div
    :class="{
      wrapper: !device,
      wrappermobile: device,
    }"
  >
    <menu-ver type="user" :id_emp="id_emp" />
    <div class="container m-3">
      <div
        :class="{
          tableheader: !device,
          tableheadermobile: device,
        }"
      >
        <h1>{{ $t("mUser.courses.title") }}</h1>
      </div>
      <table class="table" v-if="courses && courses.length > 0">
        <thead>
          <tr>
            <th scope="col">{{ $t("mUser.courses.name") }}</th>
            <th scope="col">{{ $t("mUser.courses.theme") }}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in courses" :key="item.curso_id">
            <td>{{ item.curso_titulo }}</td>
            <td>{{ item.curso_temas }}</td>
            <td v-if="item.curso_fin===0">
              <button
                class="btn button buttonEdit"
                @click="seeCurse(item.curso_id)"
                :style="{ backgroundColor: colorCompany }"
                :data-title="$t(`menu.vercurso`)"
              >
                <i class="far fa-eye"></i>
              </button>
            </td>
            <td v-else style="text-align: right; font-weight: bold;">
              {{ $t("mUser.courses.outOfTime") }}
            </td>
          </tr>
        </tbody>
      </table>
      <p v-else>{{ $t("errors.3") }}</p>
    </div>
  </div>
</template>

<script>
import MenuVer from "../../../components/MenuVer";
import axios from "axios";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    MenuVer,
  },
  data() {
    return {
      courses: [],
      course: {},
      id_emp: this.$route.params.idemp,
    };
  },
  created() {
    this.getcourses();
    this.changeBreadCrumb(32);
    this.bigDrop();
  },
  computed: {
    ...mapState(["servidor", "colorCompany", "device"]),
  },
  methods: {
    ...mapMutations(["changeBreadCrumb", "bigDrop"]),
    async getcourses() {
      try {
        const res = await axios.post(
          `${this.servidor}api/op_usu.asp?tp=miscursos&ac=listado`
        );
        if (res.data) {
          this.courses = res.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    seeCurse(id) {
      let routeUrl = this.$router.resolve({
        path: `/${this.id_emp}/student/mycourse/${id}`,
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}
.btn {
  float: right;
}
[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  background-color: #333;
  color: #fff;
  font-size: 14px;
  position: absolute;
  padding: 10px 20px;
  bottom: 2em;
  left: -5em;
  font-family: "Roboto", sans-serif;
  white-space: nowrap;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
  border-radius: 6px;
}

[data-title] {
  position: relative;
}
</style>